import TotalColocaciones from "../../charts/TotalColocacione";
import ColocacionesComerciales from "../../charts/ColocacionesComerciales";
import ColocacionesConsumo from "../../charts/ColocacionesConsumo";
import ColocacionesVivienda from "../../charts/ColocacionesVivienda";
import ActivosConsolidados from "../../charts/ActivosConsolidados";
import { getInfoBankTransaction } from "../../../services/bankTransaction/bankTransaction";
import { useCallback, useEffect, useState } from "react";
import { getBanks } from "../../../services/banks/bank";
import { CircularProgress, Select, SelectItem } from "@nextui-org/react";

export default function Colocaciones() {
  const [colocacionesComercialesState, setColocacionesComercialesState] =
    useState([]);
  const [colocacionesConsumoState, setColocacionesConsumoState] = useState([]);
  const [colocacionesViviendaState, setColocacionesViviendaState] = useState(
    []
  );
  const [colocacionesTotalState, setColocacionesTotalState] = useState([]);
  const [totalActivosColocacionesState, setTotalActivosColocacionesState] =
    useState([]);
  const [banksSelect, setBanksSelect] = useState([
    "001",
    "016",
    "012",
    "037",
    "014",
  ]);

  const [loader, setLoader] = useState(false);

  const [banks, setBanks] = useState([]);

  const getColocaciones = useCallback(async () => {
    const currentYear = 2024;
    const lastYear = 2025;

    const colocacionesComerciales = await getInfoBankTransaction({
      codes: ["145000000", "149500100"],
      year: [currentYear, lastYear],

      banks: banksSelect,
    });

    const colocacionesConsumo = await getInfoBankTransaction({
      codes: ["148000000", "149700100"],
      year: [currentYear, lastYear],
      banks: banksSelect,
    });

    const colocacionesVivienda = await getInfoBankTransaction({
      codes: ["146000000", "149600100"],
      year: [currentYear, lastYear],
      banks: banksSelect,
    });

    const colocacionesTotal = await getInfoBankTransaction({
      codes: [
        "145000000",
        "149500100",
        "148000000",
        "149700100",
        "146000000",
        "149600100",
      ],
      year: [currentYear, lastYear],
      banks: banksSelect,
    });

    const activosConsolidados = await getInfoBankTransaction({
      codes: [
        "105000000",
        "500000000",
        "505000000",
        "113000300",
        "115500300",
        "118500300",
        "123000300",
        "143150100",
        "143250100",
        "149000000",
        "143300100",
        "143400100",
        "112000000",
        "115250000",
        "118250000",
        "122000000",
        "141500000",
        "111000100",
        "130000100",
        "141000000",
        "150000300",
        "150000400",
        "150000500",
        "150000600",
        "150000700",
        "150000800",
        "160000000",
        "170000000",
        "175000000",
        "195000000",
      ],
      banks: banksSelect,
    });

    const promisees = await Promise.all([
      colocacionesComerciales,
      colocacionesConsumo,
      colocacionesVivienda,
      colocacionesTotal,
      activosConsolidados,
    ]);

    Object.entries(promisees).forEach(([key, value]) => {
      if (key === "0") {
        setColocacionesComercialesState(value.res);
      } else if (key === "1") {
        setColocacionesConsumoState(value.res);
      } else if (key === "2") {
        setColocacionesViviendaState(value.res);
      } else if (key === "3") {
        setColocacionesTotalState(value.res);
      } else if (key === "4") {
        setTotalActivosColocacionesState(value.res);
      }
    });

    setLoader(false);
  }, [banksSelect]);

  const getEnum = useCallback(async () => {
    const banks = await getBanks();

    setBanks(
      banks.res.map((bank) => {
        return {
          label: bank.name,
          value: bank.code,
        };
      })
    );
  }, []);

  useEffect(() => {
    getEnum();
  }, [getEnum]);

  useEffect(() => {
    setLoader(true);
    getColocaciones();
  }, [banksSelect, getColocaciones]);

  return (
    <>
      {loader && (
        <div className="w-full flex items-center justify-center">
          <CircularProgress
            color="c-primary"
            classNames={{
              svg: "w-36 h-36 drop-shadow-md",
              track: "stroke-white/10",
              value: "text-3xl font-semibold text-white",
            }}
          />
        </div>
      )}

      {!loader && (
        <>
          <div className="flex w-full max-w-xs flex-col gap-2">
            <Select
              label="Bancos"
              selectionMode="multiple"
              selectedKeys={banksSelect}
              className="max-w-xs"
              onSelectionChange={(e) => {
                setBanksSelect(Array.from(e));
              }}
            >
              {banks.map((animal) => (
                <SelectItem key={animal.value} value={animal.value}>
                  {animal.label}
                </SelectItem>
              ))}
            </Select>
          </div>
          <ActivosConsolidados data={totalActivosColocacionesState} />
          <TotalColocaciones colocacionesTotal={colocacionesTotalState} />
          <ColocacionesComerciales data={colocacionesComercialesState} />
          <ColocacionesConsumo data={colocacionesConsumoState} />
          <ColocacionesVivienda data={colocacionesViviendaState} />
        </>
      )}
    </>
  );
}
